export default {
  computed: {
    payoutsModel() {
      return {
        payment_end_date: {
          type: 'date',
          label: this.$t('common.added'),
          width: 200,
        },
        paid: {
          type: 'common',
          label: this.$t('common.sumCurrency', { currency: this.$store.getters.getCurrency }),
          sortable: true,
        },
        status: {
          type: 'status',
          label: this.$t('common.status'),
        },
        comment: {
          type: 'common',
          label: this.$t('common.comment'),
        },
      };
    },
  },
};
