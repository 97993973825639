<template>
  <div class="payouts">
    <Pane>
      <h1>{{ $t('profile.myPayouts') }}</h1>
      <DataGrid :data="getPayouts" :model="payoutsModel" />
    </Pane>
  </div>
</template>

<script>
import DataGrid from '@/components/decorative/DataGrid.vue';
import Pane from '@/components/decorative/Pane.vue';
import payoutsModel from '@/configs/table-models/payouts';

export default {
  name: 'Payouts',
  mixins: [payoutsModel],
  components: {
    Pane,
    DataGrid,
  },
  computed: {
    getPayouts() {
      return this.$store.getters.getPayouts || [];
    },
  },
  mounted() {
    if (!this.$store.getters.getPayouts.length) this.$store.dispatch('payoutsRequest');
  },
};
</script>
